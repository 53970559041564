@font-face {
  font-family: 'DankMono';
  src: url('../fonts/DankMono-Regular.ttf');
}

@font-face {
  font-family: 'DankMonoItalic';
  src: url('../fonts/DankMono-Italic.ttf');
}

a {
  color: #d39482;
  font-family: 'DankMonoItalic';
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 10%;
  width: 75%;
  font-family: 'DankMono', monospace;
}

.column {
  flex: 50%;
  max-width: 50%;
}

.left {
  text-align: right;
}

.profile-photo {
  width: 350px;
  padding: 40px;
}

.pronunciation {
  color: gray;
  font-family: 'DankMonoItalic', 'DankMono', monospace;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1.5em;
  }

  .column {
    flex: 100%;
    max-width: 100%;
  }
}
